
.tbody-search-container {
    display: block;
    max-height: calc(100vh - 30px - 100px - 125px - 25px);
    overflow: auto;
}

.tbody-container {
    display: block;
    max-height: calc(100vh - 30px - 155px - 25px);
    overflow: auto;
}

thead,

tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
    cursor: pointer;
}

.react-datepicker-custom {
    font-family: GothamLight, sans-serif !important;
    font-size: 1rem;
    background-color: #fff;
    color: #000;
    border: 1px solid #aeaeae;
    border-radius: 0.3rem;
    display: inline-block;
    width: 100%;
    /*float: left;*/
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}