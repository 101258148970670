.calendarContainer {
    /*overflow: auto;*/
    height: calc(100vh - 110px - 100px);
}

.calendarPackageContainer {
    overflow: auto;
    height: calc(100vh - 20px - 100px - 150px);
}

.weekdayContainer {
    /*min-width: 20%;
    --n: 2;*/
    overflow: auto;
    height: calc(100vh - 20px - 100px - 90px);
}

.calendarPackagePlaceHolder {
    height: calc(100vh - 70px - 100px - 150px);
}

.ui.divided.grid:not([class*="vertically divided"]) > .column:not(.row),
.ui.divided.grid:not([class*="vertically divided"]) > .row > .column {
    -webkit-box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.6);
    box-shadow: -1px 0 0 0 rgba(0, 0, 0, 0.6);
}