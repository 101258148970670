.pipeContainer {
    overflow: auto;
    height: calc(100vh - 40px - 100px);
}

.packageContainer {
    overflow: auto;
    height: calc(100vh - 27px - 100px - 150px);
}

.placeContainer {
    min-width: 20%;
    width: calc(100% / var(--n));
}

.placeContainerMobile {
    width: 91%;
}

.packagePlaceHolder {
    height: calc(100vh - 70px - 100px - 150px);
}

.minimizedContent{
    height: calc(100vh - 41px - 140px);
    max-width: 48px;
    min-width: 48px;
}

.minimizedContentMobile{
    max-height: 50px;
    min-height: 50px;
    min-width: 20%;
    width: 91%;
}