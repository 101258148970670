/*noinspection ALL*/
#page-container {
    /*position: relative;*/
    min-height: 100vh;
}

#content-wrap {
    /*padding-bottom: 20px;*/
    padding-top: 100px; /*80px*/
}

#footer {
    /*position: absolute;*/
    position: fixed;
    bottom: 0;
    width: 100%;
    max-height: 90px;
}

.tableContainer {
    background-color: #E8E8E8;
}

.publicContainer {
    padding-bottom: 90px;
}

.privateContainer {
    padding-bottom: 20px;
}
