.image404 {
    height: 60vh;
    text-align: center;
    margin: 0 auto;
}

.image5xx {
    padding-top: 5vh;
    height: 90vh;
    text-align: center;
    margin: 0 auto;
}
